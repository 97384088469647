import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.es';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import MicroBenefit from '../components/microbenefit.component';
import BottomCTA from '../components/bottomCTA.component.en';

import '../styles/product.scss';
import introProductImage from '../static/images/KuFlow-Organizaciones-Digitales.gif';

export default function Product() {
	return (
		<LayoutEN>
			<Seo title='Product' />
			<section id='introProduct'>
				<div className='introProduct-columns'>
					<div id='introProduct-text' class='introProduct-column'>
						<h1>
							Finally, this was the digital transformation you've heard so much
							about.
						</h1>
						<p>
							KuFlow is a virtual orchestra conductor which, with his baton,{' '}
							<strong>
								will manage people, bots, tasks, files and processes
							</strong>{' '}
							to keep your business on track.
						</p>
						<p style={{ width: '100%' }}>
							Is <i>Company-on-Rails</i> already taken? Can we?
						</p>
					</div>
					<div id='introProduct-image' class='introProduct-column'>
						<img src={introProductImage} alt='Smooth work with KuFlow' />
					</div>
				</div>
			</section>
			<section id='explanation'>
				<h2 style={{ marginBottom: '1rem' }}>KuFlow in 2 minutes</h2>
				<iframe
					id='youtube_player'
					className='modal-content-video-inner'
					width='960'
					height='540'
					src='https://www.youtube.com/embed/cMg9Et90ZP4'
					title='YouTube video player'
					frameborder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowfullscreen
				></iframe>
				<div class='benefits'>
					<MicroBenefit imageUrl='/images/kuflow_orquestacion_tareas.jpg'>
						<h3>Tasks orchestration</h3>
						<p>
							KuFlow is a <strong>developer-friendly</strong> platform in which
							you can define processes with code. Thanks to this,{' '}
							<strong>you can program anything you can think of</strong>, for
							example:
						</p>
						<ul>
							<li>Who has to do what (with a powerful permissions system).</li>
							<li>
								Which documents have to be attached and where they have to be
								stored.
							</li>
							<li>
								What decisions have to be made, whether human or based on rules.
							</li>
							<li>What happens when some task or process changes state.</li>
						</ul>
						<p></p>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_interacción_humano-maquina.jpg'>
						<h3>Human-machine interaction</h3>
						<p>
							KuFlow will be your <strong>virtual assistant</strong>. It'll
							remind you pending tasks, documents that need to be uploaded or
							some process depending simply on your decision.
						</p>
						<p>
							{' '}
							<strong>KuFlow wants you to focus only on your work</strong>,
							giving you a sort of virtual desktop where you don't have to be
							with 4 windows and 22 tabs to keep everything in order.
						</p>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_rpa.jpg'>
						<h3>No human needed tasks</h3>
						<p>
							KuFlow doesn't just make lists, <strong>it finishes tasks</strong>
							.
						</p>
						<p>
							Teach one of your bots what he has to do and KuFlow takes care of
							it. You'll wish you had interns like that.
						</p>
					</MicroBenefit>
					<MicroBenefit imageUrl='/images/kuflow_dashboard.jpg'>
						<h3>Everything under control</h3>
						<p>
							With the manager role{' '}
							<strong>you can see who is stuck or who is skipping out</strong>{' '}
							on specific types of tasks.<p></p> But don't feel bad, it's not
							micromanagement if you only check it a couple of times a week
							*wink wink*.
						</p>
					</MicroBenefit>
				</div>
			</section>
			<SolutionsBlockEN />
			<BottomCTA />
		</LayoutEN>
	);
}
